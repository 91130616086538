import { combineReducers } from 'redux';
import App from '@redux/app/reducer';
import auth from '@redux/auth/reducer';
import ThemeSwitcher from '@redux/themeSwitcher/reducer';

import soilSensorData from './soilSensorData/reducer';
import irrigationLog from './irrigationLog/reducer';
import orchardSettings from './orchardSettings/reducer';
import LanguageSwitcher from '@redux/languageSwitcher/reducer';

import { connectRouter } from 'connected-react-router'

export const createRootReducer = (history) => combineReducers({
  auth,
  soilSensorData,
  irrigationLog,
  orchardSettings,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  router: connectRouter(history),
});