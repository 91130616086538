export const orchardSettingsActions = {
  GET_ORCHARD_SETTINGS: "GET_ORCHARD_SETTINGS",
  GET_ORCHARD_SETTINGS_SUCCESS: "GET_ORCHARD_SETTINGS_SUCCESS",
  GET_ORCHARD_SETTINGS_ERROR: "GET_ORCHARD_SETTINGS_ERROR",
  CREATE_ORCHARD_SETTINGS: "CREATE_ORCHARD_SETTINGS",
  CREATE_ORCHARD_SETTINGS_SUCCESS: "CREATE_ORCHARD_SETTINGS_SUCCESS",
  CREATE_ORCHARD_SETTINGS_ERROR: "CREATE_ORCHARD_SETTINGS_ERROR",
  UPDATE_ORCHARD_SETTINGS: "UPDATE_ORCHARD_SETTINGS",
  UPDATE_ORCHARD_SETTINGS_SUCCESS: "UPDATE_ORCHARD_SETTINGS_SUCCESS",
  UPDATE_ORCHARD_SETTINGS_ERROR: "UPDATE_ORCHARD_SETTINGS_ERROR",
  getOrchardSettings: (orchardId) => ({ type: orchardSettingsActions.GET_ORCHARD_SETTINGS, orchardId }),
  getOrchardSettingsSuccess: (response) => ({ type: orchardSettingsActions.GET_ORCHARD_SETTINGS_SUCCESS, response }),
  getOrchardSettingsError: (error) => ({ type: orchardSettingsActions.GET_ORCHARD_SETTINGS_ERROR, error }),
  updateOrchardSettings: (orchardId, payload) => ({ type: orchardSettingsActions.UPDATE_ORCHARD_SETTINGS, orchardId, payload }),
  updateOrchardSettingsSuccess: (response) => ({ type: orchardSettingsActions.UPDATE_ORCHARD_SETTINGS_SUCCESS, response }),
  updateOrchardSettingsError: (error) => ({ type: orchardSettingsActions.UPDATE_ORCHARD_SETTINGS_ERROR, error }),
  createOrchardSettings: (payload) => ({ type: orchardSettingsActions.CREATE_ORCHARD_SETTINGS, payload }),
  createOrchardSettingsSuccess: (response) => ({ type: orchardSettingsActions.CREATE_ORCHARD_SETTINGS_SUCCESS, response }),
  createOrchardSettingsError: (error) => ({ type: orchardSettingsActions.CREATE_ORCHARD_SETTINGS_ERROR, error })
};
