import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import actions from './actions';
import { push } from 'connected-react-router'

const login = async (payload) => {
  try {
    return await Auth.signIn(payload.email.toLowerCase(), payload.password);
  } catch (error) {
    console.error('error signing in: ', error);
    return error;
  }
};

const setNewUserPassword = async (user, password) => {
  try {
    return await Auth.completeNewPassword(user, password);
  } catch (error) {
    console.error('error setting new password: ', error);
    return error;
  }
};

const forgotPassword = async (email) => {
  try {
    return await Auth.forgotPassword(email.toLowerCase());
  } catch (error) {
    console.error('error in forget password: ', error);
    return error;
  }
};

const resetPassword = async (email, verificationCode, password) => {
  try {
    return await Auth.forgotPasswordSubmit(email.toLowerCase(), verificationCode, password);
  } catch (error) {
    console.error('error in reset password: ', error);
    return error;
  }
};

const signOutUser = async () => {
  try {
    return await Auth.signOut();
  } catch (error) {
    console.error('error in signing out user: ', error);
    return error;
  }
};

const checkForAuthenticatedUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log("No authenticated user found, user must sign in");
    return error;
  }
};

export function* loginSuccess(user) {
  if (user) {
    yield put({
      type: actions.LOGIN_SUCCESS,
      user: user,
      orchardId: user.signInUserSession.idToken.payload['custom:orchardId'],
    });
  }
};

export function* loginError(error) {
  yield put({ type: actions.LOGIN_ERROR, error });
};

export function* setLoading (value) {
  yield put({ type: actions.LOGIN_LOADING, payload: value });
};

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function*({ payload }) {
    yield call(setLoading, true);

      const user = yield call(login, payload);
      
      if (user) {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          yield put({
            type: actions.NEW_PASSWORD_REQURIED,
            user: user
          })
          yield call(setLoading, false);
          yield put(push('/new-password'))
        } else if (user.code === "NotAuthorizedException") {
          yield call(loginError, user);
          yield call(setLoading, false);
        } else if (user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.payload['custom:orchardId']) {
          yield call(loginSuccess, user);
          yield call(setLoading, false);
          yield put({ type: actions.LOGIN_REDIRECT, payload: true })
        } else {
          yield call(loginError, user);
          yield call(setLoading, false);
        }
      } else {
        yield call(loginError, user);
        yield call(setLoading, false);
      }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield call(signOutUser);
    yield put(push('/sign-in'))
  });
};

export function* newPasswordRequest() {
  yield takeEvery(actions.SET_NEW_USER_PASSWORD, function*({ user, password }) {
    yield call(setLoading, true);

    let response;
    if (user && password) {
      response = yield call(setNewUserPassword, user, password);
    } else {
      yield call(loginError, { message: "Unknown user"});
      yield call(setLoading, false);
    };

    if (response.code === "InvalidPasswordException") {
      yield call(loginError, response);
      yield call(setLoading, false);
    } else if (response.signInUserSession && response.signInUserSession.idToken && user.signInUserSession.idToken.payload['custom:orchardId']) {
      yield call(loginSuccess, response);
      yield call(setLoading, false);
      yield put(push('/'))
    } else {
      yield call(loginError, response);
      yield call(setLoading, false);
    }
  });
};

export function* resetPasswordRequest() {
  yield takeEvery(actions.RESET_USER_PASSWORD, function*({ email, password, verificationCode }) {
    yield call(setLoading, true);

    const response = yield call(resetPassword, email.toLowerCase(), verificationCode, password);

    if (response && response.code === "ExpiredCodeException") {
      yield call(loginError, response);
      yield call(setLoading, false);
    } else if (response && response.code === "InvalidPasswordException") {
      yield call(loginError, response);
      yield call(setLoading, false);
    } else if (response && response.code === "LimitExceededException") {
      yield call(loginError, response);
      yield call(setLoading, false);
    } else {
      yield call(setLoading, false);
      yield put(push('/'));
    }
  });
};

export function* forgotPasswordRequest() {
  yield takeEvery(actions.FORGOT_PASSWORD, function*({ email }) {
    yield call(setLoading, true);

    const response = yield call(forgotPassword, email.toLowerCase());

    if (response.CodeDeliveryDetails) {
      yield call(setLoading, false);
      yield put({ type: actions.CLEAR_ERROR });
      yield put(push('/reset-password'));
    } else if (response && response.code === "LimitExceededException") {
      yield call(loginError, response);
      yield call(setLoading, false);
    }
  });
};

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    console.log("Checking for authenticated user");

    const user = yield call(checkForAuthenticatedUser);

    if (user && user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.payload['custom:orchardId']) {
      console.log("User session still active, logging in...")
      yield call(loginSuccess, user);
      yield call(setLoading, false);
      yield put({ type: actions.LOGIN_REDIRECT, payload: true })
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(newPasswordRequest),
    fork(resetPasswordRequest),
    fork(forgotPasswordRequest),
    fork(logout),
  ]);
}
