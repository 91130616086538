import actions from './actions';

const initState = { 
  orchardId: null,
  isLoggedIn: false,
  isPremiumUser: false,
  isStandarUser: true,
  loading: false,
  loginRedirect: false,
  incorrectUsernameOrPassword: false,
  loginError: null,
  user: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return { ...state, user: action.user, orchardId: action.orchardId, isLoggedIn: true, incorrectUsernameOrPassword: false, loginError: null };
    case actions.LOGIN_LOADING:
      return { ...state, loading: action.payload };
    case actions.LOGIN_REDIRECT:
      return { ...state, loginRedirect : action.payload}
    case actions.NEW_PASSWORD_REQURIED:
      return { ...state, user: action.user, loginError: null, incorrectUsernameOrPassword: false }
    case actions.LOGIN_ERROR:
      return { ...state, loginError: action.error, incorrectUsernameOrPassword: true }
    case actions.CLEAR_ERROR:
      return { ...state, loginError: null, incorrectUsernameOrPassword: false };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
