import { all } from 'redux-saga/effects';
import authSagas from '@redux/auth/saga';
import soilSensorDataSaga from '@redux/soilSensorData/saga';
import irrigationLogSaga from '@redux/irrigationLog/saga';
import orchardSettingsSaga from './orchardSettings/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    soilSensorDataSaga(),
    irrigationLogSaga(),
    orchardSettingsSaga()
  ]);
}
