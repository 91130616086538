import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { orchardSettingsActions } from './actions';
import { get, post } from '../../api';

export function* getOrchardSettings() {
  yield takeEvery(orchardSettingsActions.GET_ORCHARD_SETTINGS, function*({ orchardId }) {
    try {
      const response = yield call(get, ['orchard-settings', orchardId]);

      if (response && response.data) {
        console.log("Retrieved orchard settings")
        yield put({ type: orchardSettingsActions.GET_ORCHARD_SETTINGS_SUCCESS, response: response.data });
      }
    } catch (error) {
      console.error("Unable to retrieve orchard settings: ", error)
    }
  });
}

export function* createOrchardSettings () {
  yield takeEvery(orchardSettingsActions.CREATE_ORCHARD_SETTINGS, function*({ payload }) {
    console.log("createOrchardSettings: ", payload)

    try {
      const response = yield call(post, ['settings', payload.orchardId, payload]);
      // yield put(push('/irrigation-log'))
      console.log("createOrchardSettings response: ", response);
    } catch (error) {
      
    }
  });
}

export function* updateOrchardSettings () {
  yield takeEvery(orchardSettingsActions.UPDATE_ORCHARD_SETTINGS, function*({ orchardId, payload }) {
    console.log("updateOrchardSettings: ", payload)

    try {
      yield call(post, ['orchard-settings', orchardId, payload]);
      yield put({ type: orchardSettingsActions.UPDATE_ORCHARD_SETTINGS_SUCCESS, payload });
    } catch (error) {
      
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getOrchardSettings),
    fork(createOrchardSettings),
    fork(updateOrchardSettings),
  ]);
}
