import Amplify from 'aws-amplify';

const get = async ([apiName, orchardId, queryStringParameters]) => {
  try {
    const params = { 
      headers: {
        'Accept': 'application/json',
        // 'x-api-key': 'nQtVPsvW2d3CDbtpjRCrn8ZyplOLcrmX9RgVfkrF',
        "orchard-id": orchardId,
        'Content-type': 'application/json; charset=UTF-8'
      },
      queryStringParameters: queryStringParameters,
      response: true       
    }
    return await Amplify.API.get(apiName, '/', params);
  } catch (error) {
    console.error(`Error getting data from ${apiName} api: `, error);
    throw error;
  }
}

const post = async ([apiName, orchardId, payload]) => {
  try {
    return await Amplify.API.post(apiName, '/', {
      body: payload,
      headers: {
        'Accept': '*/*',
        // 'x-api-key': 'nQtVPsvW2d3CDbtpjRCrn8ZyplOLcrmX9RgVfkrF',
        'orchard-id': orchardId,
        'Content-Type': 'text/plain'
      } 
    });
  } catch (error) {
    console.error(`Error posting data from ${apiName} api: `, error);
    throw error;
  }
}

export {
  get,
  post,
}