import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { get } from '../../api';
import moment from 'moment-timezone';
import { soilSensorActions } from './actions';

export function* getSoilSensorAndPredictionData() {
  // TODO: implement
}

export function* getSoilSensorData() {
  yield takeEvery(soilSensorActions.GET_SOIL_SENSOR_DATA, function*({ orchardId, range }) {

    try {
      const queryStringParameters = {
        'start': range ? moment.utc(range[0]).tz("Pacific/Auckland").format("DD-MM-YYYY H:mm") : moment.utc().tz("Pacific/Auckland").add(-1, 'week').format("DD-MM-YYYY H:mm"),
        'end': range ? moment.utc(range[1]).tz("Pacific/Auckland").format("DD-MM-YYYY H:mm") : moment.utc().tz("Pacific/Auckland").format("DD-MM-YYYY H:mm")
      }
      const response = yield call(get, ['sensor-data', orchardId, queryStringParameters]);

      if (response && response.data) {
        yield put({ type: soilSensorActions.GET_SOIL_SENSOR_DATA_SUCCESS, measured: response.data });
      }
    } catch (error) {
      console.error("Unable to retrieve soil sensor data: ", error);
    }
  });
};

export default function* rootSaga() {
  yield all([
    fork(getSoilSensorAndPredictionData),
    fork(getSoilSensorData)
  ]);
}
