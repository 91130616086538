import { soilSensorActions } from './actions';

const initState = { 
  measured: [],
  prediciton: [],
  error: null
};

export default function soilSensorDataReducer(state = initState, action) {
  switch (action.type) {
    case soilSensorActions.GET_SOIL_SENSOR_AND_PREDICTION_DATA_SUCCESS:
      return { ...state, measured: [...action.measured], prediciton: [...action.prediciton]}
    case soilSensorActions.GET_SOIL_SENSOR_DATA_SUCCESS:
      return { ...state, measured: action.measured };
    case soilSensorActions.GET_SOIL_SENSOR_AND_PREDICTION_DATA_ERROR:
      return { ...state, loginError: action.error };
    default:
      return state;
  }
}
