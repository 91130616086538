import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createRootReducer} from './root-reducer';
import rootSaga from './root-saga';

import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

export const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware
      ),
    ),
  )

  return store
}

const store = configureStore();
sagaMiddleware.run(rootSaga);
export { store };