export const soilSensorActions = {
  GET_SOIL_SENSOR_AND_PREDICTION_DATA: 'GET_SOIL_SENSOR_AND_PREDICTION_DATA',
  GET_SOIL_SENSOR_AND_PREDICTION_DATA_SUCCESS: 'GET_SOIL_SENSOR_AND_PREDICTION_DATA_SUCCESS',
  GET_SOIL_SENSOR_AND_PREDICTION_DATA_ERROR: 'GET_SOIL_SENSOR_AND_PREDICTION_DATA_ERROR',
  GET_SOIL_SENSOR_DATA: 'GET_SOIL_SENSOR_DATA',
  GET_SOIL_SENSOR_DATA_SUCCESS: 'GET_SOIL_SENSOR_DATA_SUCCESS',
  GET_SOIL_SENSOR_DATA_ERROR: 'GET_SOIL_SENSOR_DATA_ERROR',
  getSoilSensorAndPredictionData: (orchardId) => ({ type: soilSensorActions.GET_SOIL_SENSOR_AND_PREDICTION_DATA, orchardId }),
  getSoilSensorAndPredictionDataSuccess: (response) => ({ type: soilSensorActions.GET_SOIL_SENSOR_AND_PREDICTION_DATA_SUCCESS, measured: response.measured, prediction: response.prediction }),
  getSoilSensorAndPredictionDataError: (error) => ({ type: soilSensorActions.GET_SOIL_SENSOR_AND_PREDICTION_DATA_ERROR, error }),
  getSoilSensorData: (orchardId) => ({ type: soilSensorActions.GET_SOIL_SENSOR_DATA, orchardId }),
  getSoilSensorDataSuccess: (response) => ({ type: soilSensorActions.GET_SOIL_SENSOR_DATA_SUCCESS, measured: response }),
  getSoilSensorDataError: (error) => ({ type: soilSensorActions.GET_SOIL_SENSOR_DATA_ERROR, error }),
};
