import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from './redux/store'
import ErrorBoundary from './ErrorBoundary';
import Loader from '@components/utility/loader';
import { ConnectedRouter } from 'connected-react-router'
import { useDispatch } from 'react-redux';
import authAction from '@redux/auth/actions';
const { checkAuthorization } = authAction;

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: "/sign-in",
    exact: true,
    component: lazy(() => import('@containers/Pages/SignIn/SignIn')),
  },
  {
    path: "/404",
    component: lazy(() => import('@containers/Pages/404/404')),
  },
  {
    path: "/500",
    component: lazy(() => import('@containers/Pages/500/500')),
  },
  {
    path: "/sign-in",
    component: lazy(() => import('@containers/Pages/SignIn/SignIn')),
  },
  {
    path: "/forgot-password",
    component: lazy(() =>
      import('@containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: "/new-password",
    component: lazy(() =>
      import('@containers/Pages/NewPassword/NewPassword')
    ),
  },
  {
    path: "/reset-password",
    component: lazy(() =>
      import('@containers/Pages/ResetPassword/ResetPassword')
    ),
  }
];

export const privateRoutes = [
	{
		path: '/',
		component: lazy(() => import('@containers/Dashboard/DashboardComponents')),
		exact: true,
	},
	{
		path: '/irrigation-calculator',
		component: lazy(() => import('@containers/IrrigationCalculator/IrrigationCalculator')),
	},
	{
		path: '/irrigation-log',
		component: lazy(() => import('@containers/IrrigationLog/IrrigationLog')),
	},
	{
		path: '/profile',
		component: lazy(() => import('@containers/Profile/Profile')),
	}
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <Route
      {...rest}
      exact
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  const dispatch = useDispatch();
  dispatch(checkAuthorization());
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>

        <ConnectedRouter history={history}>
          <>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))}
              <PrivateRoute path="/">
                <Dashboard />
              </PrivateRoute>
            </Switch>
          </>
        </ConnectedRouter>

      </Suspense>
    </ErrorBoundary>
  );
}
