export const irrigationLogActions = {
  GET_IRRIGATION_LOG: 'GET_IRRIGATION_LOG',
  GET_IRRIGATION_LOG_SUCCESS: 'GET_IRRIGATION_LOG_SUCCESS',
  GET_IRRIGATION_LOG_ERROR: 'GET_IRRIGATION_LOG_ERROR',
  CREATE_IRRIGATION_LOG: 'CREATE_IRRIGATION_LOG',
  CREATE_IRRIGATION_LOG_SUCCESS: 'CREATE_IRRIGATION_LOG_SUCCESS',
  CREATE_IRRIGATION_LOG_ERROR: 'CREATE_IRRIGATION_LOG_ERROR',
  UPDATE_IRRIGATION_LOG: 'UPDATE_IRRIGATION_LOG',
  UPDATE_IRRIGATION_LOG_SUCCESS: 'UPDATE_IRRIGATION_LOG_SUCCESS',
  UPDATE_IRRIGATION_LOG_ERROR: 'UPDATE_IRRIGATION_LOG_ERROR',
  getIrrigationLog: (payload) => ({ type: irrigationLogActions.GET_IRRIGATION_LOG, payload }),
  getIrrigationLogSuccess: (response) => ({ type: irrigationLogActions.GET_IRRIGATION_LOG_SUCCESS, response }),
  getIrrigationLogError: (error) => ({ type: irrigationLogActions.GET_IRRIGATION_LOG_ERROR, error }),
  updateIrrigationLog: (payload) => ({ type: irrigationLogActions.UPDATE_IRRIGATION_LOG, payload }),
  updateIrrigationLogSuccess: (response) => ({ type: irrigationLogActions.UPDATE_IRRIGATION_LOG_SUCCESS, response }),
  updateIrrigationLogError: (error) => ({ type: irrigationLogActions.UPDATE_IRRIGATION_LOG_ERROR, error }),
  createIrrigationLog: (payload) => ({ type: irrigationLogActions.CREATE_IRRIGATION_LOG, payload }),
  createIrrigationLogSuccess: (response) => ({ type: irrigationLogActions.CREATE_IRRIGATION_LOG_SUCCESS, response }),
  createIrrigationLogError: (error) => ({ type: irrigationLogActions.CREATE_IRRIGATION_LOG_ERROR, error })
};
