const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_LOADING: 'LOGIN_LOADING',
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  LOGIN_REDIRECT: 'LOGIN_REDIRECT',
  INCORRECT_USERNAME_OR_PASSWORD: 'INCORRECT_USERNAME_OR_PASSWORD',
  NEW_PASSWORD_REQURIED: "NEW_PASSWORD_REQURIED",
  SET_NEW_USER_PASSWORD: "SET_NEW_USER_PASSWORD",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  CLEAR_ERROR: "CLEAR_ERROR",
  setLoading: (payload) => ({ type: actions.LOGIN_LOADING, payload}),
  setIncorrectUserNameOrPassword: (payload) => ({ type: actions.INCORRECT_USERNAME_OR_PASSWORD, payload}),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  setLoginRedirect: (payload) => ({ type: actions.LOGIN_REDIRECT, payload }),
  setNewUserPassword: (user, password) => ({ type: actions.SET_NEW_USER_PASSWORD, user, password }),
  forgotPassword: (email) => ({ type: actions.FORGOT_PASSWORD, email }),
  resetPassword: (payload) => ({ type: actions.RESET_USER_PASSWORD, ...payload }),
  setLoginError: (error) => ({
    type: actions.LOGIN_ERROR,
    payload: { error }
  }),
  login: (values) => ({
    type: actions.LOGIN_REQUEST,
    payload: values,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
