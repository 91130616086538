import { irrigationLogActions } from './actions';

const initState = [];

export default function irrigationLogReducer (state = initState, action) {
  switch (action.type) {
    case irrigationLogActions.GET_IRRIGATION_LOG_SUCCESS:
      return [...action.response];
    case irrigationLogActions.UPDATE_IRRIGATION_LOG_SUCCESS:
      return [...action.response];
    default:
      return state;
  }
}
