import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { irrigationLogActions } from './actions';
import { push } from 'connected-react-router'

async function fakeApiCall(data) {
  return await new Promise(resolve => {
    const interval = setInterval(() => {
        resolve(data);
        clearInterval(interval);
    }, 500);
  });
}

export function* getIrrigationLog() {
  yield takeEvery(irrigationLogActions.GET_IRRIGATION_LOG, function*({ payload }) {
    console.log("getIrrigationLogSuccess: ", payload)

    try {
      const response = yield call(fakeApiCall, []);
      yield put(push('/irrigation-log'), response)
    } catch (error) {
      
    }
  });
}

export function* createIrrigationLog () {
  yield takeEvery(irrigationLogActions.CREATE_IRRIGATION_LOG, function*({ payload }) {
    console.log("createIrrigationLog: ", payload)

    try {
      const response = yield call(fakeApiCall, []);
      console.log("createIrrigationLog response: ", response)
      yield put({ type: irrigationLogActions.CREATE_IRRIGATION_LOG_SUCCESS, response });
    } catch (error) {
      
    }
  });
}

export function* updateIrrigationLog () {
  yield takeEvery(irrigationLogActions.UPDATE_IRRIGATION_LOG, function*({ payload }) {
    console.log("updateIrrigationLog: ", payload)
    const response = yield call(fakeApiCall, []);
    yield put({ type: irrigationLogActions.UPDATE_IRRIGATION_LOG_SUCCESS, response });
  });
}

export default function* rootSaga() {
  yield all([
    fork(getIrrigationLog),
    fork(createIrrigationLog),
    fork(updateIrrigationLog),
  ]);
}
