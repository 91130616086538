import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';

import Amplify from 'aws-amplify';
import awsConfigs from './aws_exports.json';

const env = process.env.NODE_ENV;
console.log(`Setting up api's for ${env} environment`);
const configs = awsConfigs[env];
Amplify.configure(configs);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
