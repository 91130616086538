import { orchardSettingsActions } from './actions';

const initState = {};

export default function orchardSettings (state = initState, action) {
  switch (action.type) {
    case orchardSettingsActions.GET_ORCHARD_SETTINGS_SUCCESS:
      return { ...action.response };
    case orchardSettingsActions.UPDATE_ORCHARD_SETTINGS_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
}
